import React, {useState, useEffect, useRef} from 'react';
import 'allsettled-polyfill';
import {Upload, Row, Col, Radio, Steps, Space, Typography, Input, Form, Button, message, Checkbox} from 'antd';
import {ErrorBoundary} from 'react-error-boundary';
import {reduce} from 'lodash';
import {UploadOutlined, DeleteOutlined, LoadingOutlined, CaretLeftOutlined} from '@ant-design/icons';
import AppPreviews from 'components/AppPreviews';
import BasicCatalogueInput from 'components/inputs/BasicCatalogueInput';
import ServicesInput from 'components/inputs/ServicesInput';
import SocialInput from 'components/inputs/SocialInput';
import SaleSiteManager from 'components/inputs/SaleSiteManager';
import styles from './styles.module.css';

const {Step} = Steps;
const {Title, Text} = Typography;
const maxStep = 7;

function ErrorShown() {
  return (
    <section>
      <Text>Une erreur est survenue lors de l'affichage de cet élément.</Text>
    </section>
  )
}

export default function ProducerForm({onBack}) {
  const [currentStep, stateSetCurrentStep] = useState(0);
  const [blobUrl, setBlobUrl] = useState(null);
  const mainPictureUpload = useRef(null);
  const [fullFormContent, setFullFormContent] = useState([]);
  const [formStatics, setFormStatics] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const newStep = window.localStorage.getItem('subscribeForm.pro.currentStep');
    if (newStep) {
      stateSetCurrentStep(parseInt(newStep, 10));
    }

    const stFullFormContent = [];
    for (let i = 0; i < maxStep; i++) {
      const stStepContent = window.localStorage.getItem(`subscribeForm.pro.step.${i}`);
      if (stStepContent && stStepContent !== 'undefined') {
        try {
          const content = JSON.parse(stStepContent);
          stFullFormContent[i] = content;
        } catch (err) {
          console.error(err);
        }
      }
    }

    function loadFormValues(values) {
      let agg = {};
      values.forEach((value) => {
        if (value.mainPicture) {
          setBlobUrl(value.mainPicture);
        } else {
          agg = {...agg, ...value};
        }
      });
      form.setFieldsValue(agg);
      setFullFormContent(values);
    }

    loadFormValues(stFullFormContent);

    loadFormContent();
  }, [form]);

  function loadFormContent() {
    Promise.allSettled([
      fetch('/api/searchForm')
        .then(res => res.json()),
      fetch('/api/services')
        .then(res => res.json()),
      fetch('/api/siteTypes')
        .then(res => res.json()),
      fetch('/api/socialLinks')
        .then(res => res.json()),
      fetch('/api/paymentMeans')
        .then(res => res.json())
    ])
    .then((res) => {
      setFormStatics({
        searchForm: res[0].value,
        services: res[1].value,
        siteTypes: res[2].value,
        socialLinks: res[3].value,
        paymentMeans: res[4].value
      })
    });
  }

  function setCurrentStep(newStep) {
    if (newStep <= maxStep) {
      window.localStorage.setItem('subscribeForm.pro.currentStep', newStep);
    }
    stateSetCurrentStep(newStep);
  }

  async function handleSubmit(values) {
    const formContent = [...fullFormContent];
    if (currentStep === 2) {
      // Handle picture in base64
      const reader = new FileReader();
      if (values.mainPicture && values.mainPicture.file && blobUrl) {
        reader.readAsDataURL(values.mainPicture.file);
        const base64 = await new Promise((resolve) => {
          reader.onloadend = function() {
            resolve(reader.result);
          };
        });

        formContent[currentStep] = {mainPicture: base64};
      } else if (blobUrl && /^data:image/.test(blobUrl)) { // Already loaded from storage
        formContent[currentStep] = {mainPicture: blobUrl};
      } else {
        // No picture loaded
      }
    } else {
      formContent[currentStep] = values;
    }
    window.localStorage.setItem(`subscribeForm.pro.step.${currentStep}`, JSON.stringify(formContent[currentStep]));
    setFullFormContent(formContent);

    if (currentStep === maxStep) {
      // concat formContent
      const mergeContent = reduce(formContent, (acc, step) => {
        return {
          ...acc,
          ...step
        };
      }, {});

      setSubmitting(true);
      fetch('/api/register', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(mergeContent)
      })
      .then((res) => res.json())
      .then((result) => {
        setSubmitting(false);
        if (result.status === true) {
          window.localStorage.removeItem('subscribeForm.pro.currentStep');
          window.localStorage.removeItem('subscribeForm.userProfile');
          for (let i = 0; i < maxStep; i++) {
            window.localStorage.removeItem(`subscribeForm.pro.step.${i}`);
          }

          setCurrentStep(currentStep + 1);
        } else {
          message.error('Une erreur est survenue. Merci de réessayer. Si le problème persiste, contactez-nous.');
        }
      })
      .catch((err) => {
        setSubmitting(false);
        message.error('Une erreur est survenue. Merci de réessayer. Si le problème persiste, contactez-nous.');
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  function handlePictureUpload(upload) {
    if (upload.file) {
      if (upload.file.size / 1024 / 1024 > 4) {
        message.error('L\'image sélectionnée ne doit pas dépasser 4 Mo.');
        setBlobUrl(null);
        return;
      }
      setBlobUrl(URL.createObjectURL(upload.file));
    } else {
      setBlobUrl(null);
    }
  }

  function removePicture() {
    setBlobUrl(null);
  }

  const buttons = (
    <Row>
      <Col offset={2}>
        {currentStep <= maxStep ? (
          <Button disabled={currentStep <= 0} onClick={() => setCurrentStep(currentStep - 1)}>Précédent</Button>
        ) : null}
      </Col>
      <Col flex={1}></Col>
      <Col>
        {currentStep === maxStep && (
          <Button loading={submitting} type="primary" htmlType="submit">Soumettre</Button>
        )}

        {currentStep < maxStep && (
          <Button disabled={currentStep >= maxStep} type="primary" htmlType="submit">Suivant</Button>
        )}
      </Col>
      <Col span={2}></Col>
    </Row>
  );

  function handleStepChange(newStep) {
    // save current step
    if (currentStep >= maxStep) {
      return;
    }
    if (fullFormContent && fullFormContent[newStep]) {
      setCurrentStep(newStep);
    }
  }

  return (
    <section className={styles.root}>
      <Button type="primary" shape="round" style={{margin: '0 20px 20px 20px'}} icon={<CaretLeftOutlined />} onClick={onBack}>Retour</Button>
      <Row>
        <Col lg={4} md={6} xs={22} offset={1}>
          <Steps current={currentStep} onChange={handleStepChange} direction="vertical">
            <Step title="Charte" description="Charte du Sucrine Club" />
            <Step title="Identité" description="Identité de la société" />
            <Step title="Photo" description="Photo d'illustration" />
            <Step title="Description" description="Description de l'activité" />
            <Step title="Produits & Services" description="Types de produits et services proposés" />
            <Step title="Lieux de vente" description="Ajouter un ou plusieurs lieux de vente" />
            <Step title="Vérification" description="Aperçu de la fiche" />
            <Step title="Questions additionnelles" description="Dernières questions" icon={submitting ? (<LoadingOutlined />) : null} />
            <Step title="Statut" description="Envoi du formulaire" />
          </Steps>
        </Col>


        <Col lg={18} md={16} xs={24}>
          <Form
            layout="vertical"
            form={form}
            initialValues={{acceptChart: false}}
            onFinish={handleSubmit}
          >
            <Row>
              <Col span={20} offset={2} className={styles.formContent}>
                {currentStep === 0 ? (
                  <>
                    <p>
                      Une chose à connaître si vous voulez faire partie de notre Club 🏆, les 3 règles à respecter pour être référencé :
                    </p>
                    <p>
                      Proposer en majorité :
                    </p>
                    <ul>
                      <li>des produits locaux (façonnés à proximité de votre point de vente, périmètre de 200km)</li>
                      <li>de saison (produits dans le respect des cycles naturels)</li>
                      <li>et en circuit-court (en vente directe ou avec un intermédiaire)</li>
                    </ul>
                    <p>
                      La plupart des informations transmises seront affichées sur l'application. En toute transparence, le formulaire prend quelques minutes à remplir mais certaines questions sont facultatives : plus vous donnerez d'informations plus vous serez visibles par les consommateurs.
                    </p>
                    <p>
                      Si vous souhaitez référencer une Ruche, une AMAP ou un collectifs de producteurs, merci de nous <a href="mailto:contact@sucrine.club">contacter directement</a>, sans remplir ce formulaire.
                    </p>

                    <Form.Item valuePropName="checked" name="acceptChart" required rules={[{required: true, message: 'Vous devez accepter la charte pour poursuivre.'}, {type: 'enum', enum: [true], message: 'Vous devez accepter la charte pour poursuivre.'}]}>
                      <Checkbox>J'accepte la charte du Sucrine Club</Checkbox>
                    </Form.Item>
                  </>
                ) : null}

                {currentStep === 1 ? (
                  <>
                    <Form.Item
                      label="Nom de la société"
                      extra="Nom officiel, inscrit dans les statuts"
                      name="companyName"
                      required
                      rules={[{required: true, message: 'Le nom de la société est obligatoire.'}]}
                    >
                      <Input placeholder="GAEC de Tournefeuille" />
                    </Form.Item>
                    <Form.Item
                      label="SIREN de la société"
                      extra="Il n'apparaitra pas dans l'application"
                      name="siren"
                      required
                      rules={[{required: true, message: 'Le SIREN de la société est obligatoire.'}, {pattern: /^[0-9]{9}$/, message: 'Le SIREN doit comporter 9 chiffres.'}]}
                    >
                      <Input placeholder="899960991" />
                    </Form.Item>
                    <Form.Item
                      label="Nom affiché"
                      extra="Sera visible dans l'application"
                      name="name"
                    >
                      <Input placeholder="Le petit fromage" />
                    </Form.Item>
                    <Form.Item
                      label="Description très courte, sous-titre"
                      extra="Trois à cinq mots pour décrire votre activité"
                      name="subtitle"
                      required
                      rules={[{required: true, message: 'Le sous-titre est obligatoire.'}]}
                    >
                      <Input placeholder="Fromage au lait de chèvre" />
                    </Form.Item>

                    <Form.Item label="Votre prénom et nom" name="contacts_ownerName" rules={[{required: true, message: 'Le nom et prénom est obligatoire.'}]} required>
                      <Input placeholder="Votre prénom et nom" />
                    </Form.Item>

                    <Form.Item valuePropName="checked" name="contacts_displayOwnerNames">
                      <Checkbox>Afficher votre prénom et nom dans l'application</Checkbox>
                    </Form.Item>

                    <Form.Item label="Votre numéro de téléphone" required extra="" rules={[{required: true, message: 'Le numéro de téléphone est obligatoire'}, {pattern: /^[0-9]{10}$/, message: 'Le numéro de téléphone doit contenir 10 chiffres.'}]} name="contacts_phoneNumber">
                      <Input placeholder="Exemple : 0620212523" />
                    </Form.Item>

                    <Form.Item required label="Votre adresse e-mail" name="contacts_email" rules={[{required: true, message: 'L\'adresse email est obligatoire'}, {type: 'email', message: 'L\'adresse email doit être valide'}]}>
                      <Input placeholder="Votre adresse e-mail" />
                    </Form.Item>

                    <Form.Item label="Vous souhaitez vendre" required name="businessType">
                      <Radio.Group>
                        <Radio value="b2c">À des consommateurs exclusivement</Radio><br/>
                        <Radio value="b2b">À des consommateurs et des professionnels</Radio><br/>
                        <Radio value="b2c-b2b">À des professionnels exclusivement</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </>
                ) : null}

                {currentStep === 2 ? (
                  <>
                    <p>
                      <Text>La photo c'est la première chose que voit les consommateurs, elle doit leur donner envie d'en savoir plus, donc il faut mettre le paquet.</Text>
                    </p>
                    <Form.Item label="Photo principale" name="mainPicture" extra="4 Mo maximum, au format JPG ou PNG. En paysage">
                      <Upload
                        ref={mainPictureUpload}
                        name="file"
                        onChange={handlePictureUpload}
                        showUploadList={false}
                        beforeUpload={() => false}
                        maxCount={1}
                      >
                        <Button icon={<UploadOutlined />}>Sélectionner la photo</Button>
                      </Upload>
                    </Form.Item>
                    <p>
                      <Text type="secondary">Idéalement, la photo doit faire 1800 x 768.</Text>
                    </p>
                    {blobUrl ? (
                      <Row style={{marginBottom: 20}} gutter={[16, 16]}>
                        <Col span={8} xs={24} md={12} xxl={8}>
                          <Text strong>Fiche descriptive</Text><br/>
                          <Text type="secondary">1440 &times; 768</Text>
                          <div className={styles.picturePreview} style={{width: 225, backgroundImage: `url(${blobUrl})`}}></div>
                        </Col>
                        <Col span={8} xs={24} md={12} xxl={8}>
                          <Text strong>Aperçu sur la carte</Text><br/>
                          <Text type="secondary">944 &times; 408</Text>
                          <div className={styles.picturePreview} style={{width: 278, backgroundImage: `url(${blobUrl})`}}></div>
                        </Col>
                        <Col span={8} xs={24} md={12} xxl={8}>
                          <Text strong>Vue liste</Text><br/>
                          <Text type="secondary">350 &times; 358</Text>
                          <div className={styles.picturePreview} style={{width: 117, backgroundImage: `url(${blobUrl})`}}></div>
                        </Col>
                      </Row>
                    ) : (
                      <p>
                        En chargeant un visuel, vous trouverez les trois types d'affichage de la photo.
                      </p>
                    )}

                    {blobUrl ? (
                      <Space style={{marginBottom: '20px'}}>
                        <Button type="danger" onClick={removePicture}><DeleteOutlined /> Supprimer la photo</Button>
                      </Space>
                    ) : null}
                  </>
                ) : null}

                {currentStep === 3 ? (
                  <>
                    <Form.Item required rules={[{required: true, message: 'L\'histoire est obligatoire.'}]} label="Votre histoire" name="description" extra="Parlez-nous de votre entreprise, votre histoire, vos engagements, le type d'agriculture (bio, raisonnées, labels...). N'hésitez pas à aller faire un tour sur l'appli Sucrine Club pour voir des exemples.">
                      <Input.TextArea rows={4} placeholder="Votre histoire" />
                    </Form.Item>

                    <Form.Item label="Informations pratiques" name="usefulInformations" extra="Les informations spécifiques sur le point de vente (conditions d'accès, frais de livraison...)">
                      <Input.TextArea rows={4} placeholder="Informations pratiques" />
                    </Form.Item>
                  </>
                ) : null}

                {currentStep === 4 ? (
                  <>
                    <Form.Item label="Description des produits" extra="Listez l'ensemble des produits que vous vendez avec leur saisonnalité." name="productDescription">
                      <Input.TextArea rows={4} placeholder="Description des produits" />
                    </Form.Item>

                    <Form.Item label="Les catégories de produits vendus" extra="Sélectionnez les par ordre d'importance." name="basicCatalogue">
                      <BasicCatalogueInput searchFormContent={(formStatics && formStatics.searchForm) || []} />
                    </Form.Item>

                    <Text>Caractéristiques des produits vendus</Text>

                    <Form.Item valuePropName="checked" name="tags_productBulk" style={{minHeight: 0, marginBottom: 0}}>
                      <Checkbox>Produits en vrac (hors fruits, légumes et viande)</Checkbox>
                    </Form.Item>

                    <Form.Item valuePropName="checked" name="tags_productOrganic" style={{minHeight: 0, marginBottom: 0}}>
                      <Checkbox>Produits BIO</Checkbox>
                    </Form.Item>

                    <Form.Item name="otherProductType" style={{minHeight: 0}}>
                      <Input placeholder="Autres caractéristiques de produits" />
                    </Form.Item>

                    <Form.Item label="Les services que vous proposez" name="services" style={{minHeight: 0, marginBottom: 5}}>
                      <ServicesInput services={(formStatics && formStatics.services) || []} />
                    </Form.Item>
                    <Form.Item name="otherServices" style={{minHeight: 0}}>
                      <Input placeholder="Autres services" />
                    </Form.Item>

                    <Form.Item label="Les moyens de paiement que vous acceptez" name="paymentMeans">
                      <ServicesInput services={(formStatics && formStatics.paymentMeans) || []} />
                    </Form.Item>

                    <Form.Item label="Votre site internet" rules={[{pattern: /^https?:\/\//, message: 'L\'adresse du site internet doit commencer par http:// ou https://'}]} name="contacts_website">
                      <Input placeholder="Exemple : https://sucrine.club/" />
                    </Form.Item>

                    <Form.Item label="Vos réseaux sociaux" name="socialLinks" className={styles.socialLinks}>
                      <SocialInput socialLinks={formStatics && formStatics.socialLinks} />
                    </Form.Item>

                  </>
                ) : null}

                {currentStep === 5 ? (
                  <>
                    <p>Chez Sucrine Club, un point de vente peut être :</p>
                    <ul>
                      <li><Text strong>Occasionnel :</Text> marché ou point de dépôt</li>
                      <li><Text strong>Permanent :</Text> magasin, vente à la ferme, drive...</li>
                    </ul>
                    <p>Inscrivez sur Sucrine Club uniquement <strong>vos propres points de vente</strong>, ceux pour lesquels vous gérez la commercialisation.<br/>Pour chaque point de vente, un point sur la carte sera créé avec sa propre adresse et ses propres horaires.</p>
                    <p><Text type="secondary">Exemple : pour chacun des marchés auxquels vous participez, il est nécessaire de créer un point de ventre avec le type "Emplacement de marché".</Text></p>

                    <Form.Item
                      name="saleSites"
                      rules={[({getFieldValue}) => ({
                        validator(rule, value) {
                          // One mandatory sale site
                          if (!value || value.length === 0) {
                            return Promise.reject('Vous devez créer au moins un lieu de vente.');
                          }
                          return Promise.resolve();
                        }
                      })]}
                    >
                      <SaleSiteManager formStatics={formStatics} />
                    </Form.Item>

                    <p>Si vous souhaitez avoir une description unique par point de vente, vous pouvez soumettre un nouveau formulaire pour chacun d'entre eux.</p>
                  </>
                ) : null}

                {currentStep === 6 ? (
                  <>
                    <ErrorBoundary
                      FallbackComponent={ErrorShown}
                    >
                      <AppPreviews formStatics={formStatics} formContent={fullFormContent} />
                    </ErrorBoundary>

                    <Form.Item label="Informations complémentaires" name="informations" extra="Vous souhaitez nous faire part de toute information, remarques ?">
                      <Input.TextArea rows={4} placeholder="Informations complémentaires" />
                    </Form.Item>
                  </>
                ) : null}

                {currentStep === 7 ? (
                  <>
                    <Form.Item label="Êtes-vous intéressé pour recevoir un sticker ?" name="whichSticker">
                      <Radio.Group>
                        <Radio value="yesLocal">Oui : "ici tout est local"</Radio><br/>
                        <Radio value="yesRegional">Oui : "ici tout est régional"</Radio><br/>
                        <Radio value="no">Non</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Avez-vous 5 adresses de professionnels du circuit-court de votre entourage à nous partager ?" name="recommendedAddresses">
                      <Input.TextArea rows={5} placeholder="Adresses recommandées" />
                    </Form.Item>

                    <Form.Item label="Etes-vous présent sur d'autres plateformes avec une présence digitale ?" name="digitalPresence">
                      <Checkbox.Group>
                        <Checkbox value="socleo">Socleo</Checkbox><br/>
                        <Checkbox value="rucheQuiDitOui">La Ruche qui dit Oui</Checkbox><br/>
                        <Checkbox value="bienvenueALaFerme">Bienvenue à la ferme</Checkbox><br/>
                        <Checkbox value="kuupanda">Kuupanda</Checkbox><br/>
                        <Checkbox value="cagette.net">Cagette.net</Checkbox><br/>
                        <Checkbox value="Google Maps">Google Maps</Checkbox><br/>
                        <Checkbox value="others">Autres :</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                      style={{marginTop: -10}}
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.digitalPresence !== curValues.digitalPresence
                      }
                    >
                      {() => (
                        <Form.Item label="" name="digitalPresenceOthers">
                          <Input disabled={!(form.getFieldValue('digitalPresence') || []).includes('others')} placeholder="Autres" />
                        </Form.Item>
                      )}
                    </Form.Item>

                    <Form.Item label="Avez-vous un smartphone ?" name="hasSmartphone">
                      <Radio.Group>
                        <Radio value="yes">Oui</Radio>
                        <Radio value="no">Non</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Êtes-vous habitué des applications ?" name="usedToApp">
                      <Radio.Group>
                        <Radio value="yes">Oui</Radio>
                        <Radio value="no">Non</Radio>
                        <Radio value="some">Un peu</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Comment avez-vous connu le projet ?" name="howDidYouKnowSucrine">
                      <Input placeholder="Comment avez-vous connu le projet ?" />
                    </Form.Item>
                  </>
                ) : null}

                {currentStep === 8 ? (
                  <>
                    <Title level={1}>
                      Merci !
                    </Title>
                    <p>
                      Le formulaire a bien été envoyé. Nous reviendrons vers vous rapidement.
                    </p>
                  </>
                ) : null}
              </Col>
            </Row>
            {buttons}
          </Form>
        </Col>
      </Row>
    </section>
  );
}
