import React from 'react';
import {Checkbox} from 'antd';

export default function Checkboxes({options, value, onChange}) {
  function handleChange(ev) {
    if (ev.target.checked) {
      if (!value || !Array.isArray(value)) return onChange([ev.target.value]);
      if (!value.includes(ev.target.value)) return onChange([...value, ev.target.value]);
    } else {
      if (!value || !Array.isArray(value)) return onChange([]);
      return onChange(value.filter((v) => v !== ev.target.value));
    }
  }

  return (
    <>
      {options ? (
        options.map((option) => (
          <Checkbox key={option.value} checked={(value || []).includes(option.value)} onChange={handleChange} value={option.value}>{option.label}</Checkbox>
        ))
      ) : null}
    </>
  );
}