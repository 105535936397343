import {Button, Checkbox, Select, Form, Input, Row, Col, Typography, Modal, Space, Popconfirm} from 'antd';
import React, {useEffect, useState} from 'react';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {find} from 'lodash';
import MarketSelector from 'components/MarketSelector';
import MarketForm from 'components/MarketForm';
import MapLocator from 'components/inputs/MapLocator';
import Checkboxes from 'components/inputs/Checkboxes';
import ScheduleEditor from 'components/inputs/ScheduleEditor';
import styles from './styles.module.css';

const {Text} = Typography;

function SaleSiteCollapsed({siteTypes, value, onEdit, onRemove}) {
  const [label, setLabel] = useState(null);
  const [days, setDays] = useState(null);
  const [address, setAddress] = useState('');
  useEffect(() => {
    if (value && siteTypes && siteTypes.length > 0) {
      const type = find(siteTypes, {type: value.siteType});
      if (type) {
        if (value.siteType === 'market' && value.market) {
          setLabel(`${type.label} - ${value.market.name}`);
          if (value.activeMarketDays) {
            const d = [];
            value.activeMarketDays.forEach((dayId) => {
              const matchingDay = find(value.market.days, {value: dayId});
              if (matchingDay) {
                d.push(matchingDay.label);
              }
            });
            setDays(`Jours de présence : ${d.join(', ')}`);
          }
        } else {
          setLabel(type.label);
        }

      } else {
        setLabel('?');
      }
      const addressParts = [];
      if (value.location?.address) {
        addressParts.push(value.location.address);
      }
      if (value.location?.city) {
        addressParts.push(value.location.city);
      }
      if (value.siteType === 'market') {
        if (value.market?.location?.address) {
          addressParts.push(value.market.location.address);
        }
        if (value.market?.location?.city) {
          addressParts.push(value.market.location.city);
        }
      }
      setAddress(addressParts.join(', '));
    }
  }, [value, siteTypes]);

  return (
    <Row className={styles.siteCollapsed}>
      <Col flex={1}>
        <Text strong>{label}</Text><br/>
        <Text>{address}</Text><br/>
        {days ? (<Text type="secondary">{days}</Text>) : null}
      </Col>
      <Col>
        <Space>
          <Button size="small" onClick={onEdit} ><EditOutlined /> Modifier</Button>
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer ce lieu ?"
            onConfirm={onRemove}
            okText="Oui, supprimer"
            cancelText="Annuler"
          >
            <Button size="small" type="danger"><DeleteOutlined /> Supprimer</Button>
          </Popconfirm>
        </Space>
      </Col>
    </Row>
  )
}

function SaleSiteEditor({markets, preFillValue, siteTypes, onSubmit, onCancel}) {
  const [form] = Form.useForm();

  function submitSaleSite(values) {
    if (preFillValue) {
      return onSubmit(values, true);
    }
    return onSubmit(values);
  }

  function handleCancel() {
    onCancel();
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={preFillValue || {coordinates: [2.36, 46.33], openingSchedule: []}}
      onFinish={submitSaleSite}
    >
      <Form.Item
        label="Type de lieu de vente"
        required
        name="siteType"
        rules={[{required: true, message: 'Le type de lieu de vente est requis.'}]}
      >
        <Select style={{flex: 1}}>
          {(siteTypes || []).map((siteType) => (
            <Select.Option key={siteType.type} value={siteType.type}>{siteType.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        shouldUpdate={(prev, cur) => cur.siteType !== prev.siteType}
        className={styles.collapsable}
        style={{margin: 0}}
      >
        {() => {
          if (form.getFieldValue('siteType') === 'market') {
            return (
              <Form.Item label="Marché" name="market">
                <MarketSelector markets={markets || []} />
              </Form.Item>
            );
          }
          return null;
        }}
      </Form.Item>

      <Form.Item
        shouldUpdate={(prev, cur) => cur.siteType !== prev.siteType || cur.market?._id !== prev.market?._id}
        className={styles.collapsable}
        style={{margin: 0, minHeight: 0}}
      >
        {() => {
          const siteType = form.getFieldValue('siteType');
          const market = form.getFieldValue('market');
          if (siteType === 'market' && market && market._id === 'other') {
            return (
              <Form.Item name="newMarket" style={{margin: 0, minHeight: 0}}>
                <MarketForm />
              </Form.Item>
            );
          }
          return null;
        }}
      </Form.Item>

      <Form.Item
        shouldUpdate={(prev, cur) => cur.siteType !== prev.siteType || cur.market?._id !== prev.market?._id}
        className={styles.collapsable}
        style={{margin: 0}}
      >
        {() => {
          const siteType = form.getFieldValue('siteType');
          const market = form.getFieldValue('market');

          if (siteType === 'market' && market && market._id !== 'other') {
            return (
              <Form.Item label="Choisir les jours de présence" name="activeMarketDays">
                <Checkboxes options={(market && market.days) || []} />
              </Form.Item>
            );
          }
          return null;
        }}
      </Form.Item>

      <Form.Item
        shouldUpdate={(prev, cur) => cur.siteType !== prev.siteType}
        className={styles.collapsable}
        style={{margin: 0}}
      >
        {() => {
          if (form.getFieldValue('siteType') && form.getFieldValue('siteType') !== 'market') {
            return (
              <>
                <Form.Item label="Adresse" name={['location', 'address']}>
                  <Input placeholder="ex : Place d'Armagnac" />
                </Form.Item>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item required rules={[{required: true, message: 'Le champ code postal est requis.'}]} label="Code postal" name={['location', 'zipcode']}>
                      <Input placeholder="ex : 32800" onKeyDown={(e)=> e.keyCode === 13 ? e.preventDefault(): ''} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Form.Item required rules={[{required: true, message: 'Le champ ville est requis.'}]} label="Ville" name={['location', 'city']}>
                    <Input placeholder="ex : Eauze" onKeyDown={(e)=> e.keyCode === 13 ? e.preventDefault(): ''} />
                  </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="Emplacement précis"
                  required
                  rules={[{required: true, message: 'Le positionnement du pointeur est requis.'}]}
                  shouldUpdate={(prev, cur) =>
                    cur.location?.zipcode && cur.location.city &&
                    (
                      cur.location.zipcode !== prev.location?.zipcode
                      || cur.location?.address !== prev.location?.address
                      || cur.location.city !== prev.location?.city
                    )
                  }
                >
                  {() => {
                    return (
                      <>
                        <Text strong>Commencez par saisir l'adresse complète. Veuillez positionner le pointeur bleu le plus précisément possible sur l'endroit du lieu de vente.</Text>
                        <Form.Item  name="coordinates">
                          <MapLocator city={form.getFieldValue(['location', 'city'])} address={form.getFieldValue(['location', 'address'])} zipcode={form.getFieldValue(['location', 'zipcode'])} />
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>

                <Form.Item label="Horaires d'ouverture" valuePropName="checked" name="noOpeningSchedule"  className={styles.scheduleContainer}>
                  <Checkbox>Ne pas utiliser de grille d'horaires</Checkbox>
                </Form.Item>

                <Form.Item
                  className={styles.scheduleContainer}
                  shouldUpdate={(prev, cur) => cur.noOpeningSchedule !== prev.noOpeningSchedule}
                >
                  {() => {
                    return (
                      <Form.Item name="openingSchedule">
                        <ScheduleEditor disabled={form.getFieldValue('noOpeningSchedule')} />
                      </Form.Item>
                    );
                  }}

                </Form.Item>

                <Form.Item
                  label="Description horaires d'ouverture"
                  extra="Décrivez vos horaires d'ouverture, précisez les fermetures annuelles si nécessaire. Peut être utilisé en complément ou en remplacement de la grille d'horaire."
                  name="openingScheduleDescription"
                >
                  <Input.TextArea rows={3} placeholder="Description horaires d'ouverture" />
                </Form.Item>
              </>
            );
          }
          return null;
        }}
      </Form.Item>

      <Form.Item
        label="Numéro de téléphone du point de vente"
        extra="Si différent de votre numéro de téléphone"
        rules={[{pattern: /^[0-9]{10}$/, message: 'Le numéro de téléphone doit contenir 10 chiffres.'}]} name={['contacts', 'phoneNumber']}
      >
        <Input placeholder="Exemple : 0620212523" onKeyDown={(e)=> e.keyCode === 13 ? e.preventDefault(): ''} />
      </Form.Item>

      <Form.Item
        label="Adresse e-mail du point de vente"
        name={['contacts', 'email']}
        extra="Si différent de votre adresse email"
        rules={[{type: 'email', message: 'L\'adresse email doit être valide'}]}
      >
        <Input placeholder="Votre adresse e-mail" onKeyDown={(e)=> e.keyCode === 13 ? e.preventDefault(): ''} />
      </Form.Item>

      <Form.Item>
        <Row align="end">
          <Space>
            <Button onClick={handleCancel}>Annuler</Button>
            <Button type="primary" htmlType="submit">{preFillValue ? 'Sauvegarder' : 'Ajouter'}</Button>
          </Space>
        </Row>
      </Form.Item>
    </Form>
  )
}

export default function SaleSiteManager({formStatics, value, onChange}) {
  const [saleSites, setSaleSites] = useState([null]);
  const [markets, setMarkets] = useState([]);

  const [newSiteMode, setNewSiteMode] = useState(false);
  const [editSite, setEditSite] = useState(null);
  const [editSiteIndex, setEditSiteIndex] = useState(null);

  useEffect(() => {
    setSaleSites(value);
  }, [value]);

  useEffect(() => {
    fetch('/api/markets')
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        setMarkets(result);
      });
  }, []);

  function handleSiteSubmit(site, isEdit) {
    // setSaleSites([...saleSites, site]);
    setEditSite(null);
    setNewSiteMode(false);
    if (isEdit) {
      const nSaleSites = [...saleSites];
      nSaleSites[editSiteIndex] = site;
      onChange(nSaleSites);
    } else {
      onChange([...(saleSites || []), site]);
    }

  }

  function handleSiteCancel() {
    setEditSite(null);
    setNewSiteMode(false);
  }

  function addSaleSite() {
    // setSaleSites([...saleSites, null]);
    // setCurrentSaleSiteIndex(saleSites.length);
    setNewSiteMode(true);
  }

  function handleSiteRemove(index) {
    return () => {
      // setSaleSites([...saleSites].splice(index, 1));
      const newSaleSites = [...(saleSites || [])];
      newSaleSites.splice(index, 1);
      onChange(newSaleSites);
    };
  }

  function handleSiteEdit(index) {
    return () => {
      // setSaleSites([...saleSites].splice(index, 1));
      setEditSiteIndex(index);
      setEditSite(saleSites[index]);
      setNewSiteMode(true);
    };
  }

  return (
    <section className={styles.root}>
      <Modal
        title="Nouveau lieu de vente"
        visible={newSiteMode}
        footer={null}
        destroyOnClose
        keyboard={false}
        maskClosable={false}
        width={1000}
        onCancel={handleSiteCancel}
      >
        <SaleSiteEditor
          markets={markets}
          preFillValue={editSite}
          siteTypes={formStatics && formStatics.siteTypes ? formStatics.siteTypes : []}
          onSubmit={handleSiteSubmit}
          onCancel={handleSiteCancel}
        />
      </Modal>
      {(saleSites || []).map((item, index) => {
        return (
          <SaleSiteCollapsed
            key={`site_collapsed_${index}`}
            siteTypes={formStatics && formStatics.siteTypes ? formStatics.siteTypes : []}
            value={item}
            onRemove={handleSiteRemove(index)}
            onEdit={handleSiteEdit(index)}
          />
        );
      })}
      <Button
        onClick={addSaleSite}
        type="primary"
        disabled={newSiteMode}
      >
        Ajouter un {(saleSites && saleSites.length > 0 ? `${saleSites.length + 1}ème` : '1er')} point de vente
      </Button>
    </section>
  )
}