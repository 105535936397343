import {Row, Col} from 'antd';
import React from 'react';
import styles from './styles.module.css';

export default function Logo({showPhoneNumber}) {
  return (
    <section className={styles.root}>
      <section className={styles.logoContainer}>
        <a href="https://sucrine.club/" target="_blank" rel="noreferrer">
          <img className={styles.logo} src="https://static.sucrine.club/logo_horizontal_coloured.png" alt="Sucrine Club Logo" />
        </a>
      </section>
      <Row>
        <Col xs={24} md={8}></Col>
        <Col xs={24} md={8}>
          <h1>Formulaire d'inscription<br/>professionnel</h1>
        </Col>
        <Col xs={22} md={7} align="right">
          {showPhoneNumber ? (
            <section style={{backgroundColor: '#EEE', fontSize: 16, fontWeight: 600, textAlign: 'center', margin: '0 20px 20px 20px', padding: 10}}>
              <span>Pour obtenir de l'assistance<br/>07 80 90 01 03</span>
            </section>
          ) : null}
        </Col>
      </Row>
    </section>
  )
}