import {Checkbox} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from './styles.module.css';

const emptyArray = [];

export default function ServicesInput({services, value, onChange}) {
  const [checkedValues, setCheckedValues] = useState({});

  useEffect(() => {
    const result = {};
    if (value) {
      value.forEach((item) => {
        result[item] = true;
      });
      setCheckedValues(result);
    }
  }, [value]);

  function updateCheckbox(event, cId) {
    const result = [];
    const nCheckedValues = {...checkedValues, [cId]: event.target.checked};
    Object.keys(nCheckedValues).forEach((res) => {
      if (nCheckedValues[res]) {
        result.push(res);
      }
    });
    onChange(result);
  }

  return (
    <section className={styles.root}>
      {(services || emptyArray).map((service) => (
          <section key={service.type}>
            <Checkbox checked={checkedValues[service.type]} onChange={(ev) => updateCheckbox(ev, service.type)}>{service.label}{service.description ? ` (${service.description})`: ''}</Checkbox>
          </section>
        )
      )}
    </section>
  )
}