import React from 'react';
import {find, findIndex} from 'lodash';
import 'antd/es/date-picker/style/index';
import moment from 'moment';
import {Row, Col, Checkbox, Button, Tooltip, Space, TimePicker} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

const RangePicker = TimePicker.RangePicker;

const days = [
  {key: 'monday', label: 'Lundi'},
  {key: 'tuesday', label: 'Mardi'},
  {key: 'wednesday', label: 'Mercredi'},
  {key: 'thursday', label: 'Jeudi'},
  {key: 'friday', label: 'Vendredi'},
  {key: 'saturday', label: 'Samedi'},
  {key: 'sunday', label: 'Dimanche'}
];

const timeFormat = 'HH:mm';

export default function ScheduleEditor({onChange, value, disabled}) {
  function addTimeRange(day) {
    const dayIndex = findIndex(value, {dayOfWeek: day});
    if (dayIndex >= 0) {
      const daySchedule = {...value[dayIndex]};
      if (daySchedule.times && daySchedule.times.length > 0) {
        daySchedule.times.push({
          fromTime: daySchedule.times[daySchedule.times.length - 1].toTime + 60,
          toTime: Math.max(daySchedule.times[daySchedule.times.length - 1].toTime + 120, 1200)
        });
      } else {
        daySchedule.times = [{
          fromTime: 540,
          toTime: 1080
        }];
      }
      const newValue = value.map((item, vIndex) => {
        if (vIndex !== dayIndex) return item;
        return daySchedule;
      });
      return onChange(newValue);
    } else {
      const daySchedule = {
        fullDay: false,
        onAppointment: false,
        times: [{
          fromTime: 540,
          toTime: 1080
        }],
        dayOfWeek: day
      };
      return onChange([...(value || []), daySchedule]);
    }
  }

  function handleChange(day, index, rangeValue, key, cbValue) {
    const dayIndex = findIndex(value, {dayOfWeek: day});
    if (!index && !rangeValue && key) {
      if (dayIndex >= 0) {
        const daySchedule = {...value[dayIndex]};
        daySchedule[key] = cbValue;
        const newValue = value.map((item, vIndex) => {
          if (vIndex !== dayIndex) return item;
          return daySchedule;
        });
        return onChange(newValue);
      } else {
        const daySchedule = {
          fullDay: false,
          onAppointment: false,
          times: [],
          dayOfWeek: day,
          ...{[key]: cbValue}
        };
        return onChange([...value, daySchedule]);
      }
    }
    if (dayIndex >= 0) {
      const daySchedule = {...value[dayIndex]};
      if (rangeValue === null) {
        daySchedule.times.splice(index, 1);
      } else if (rangeValue.length === 2){
        const values = [
          ((rangeValue[0].toDate().getTime() + rangeValue[0].utcOffset() * 60000) % 86400000) / 1000 / 60,
          ((rangeValue[1].toDate().getTime() + rangeValue[1].utcOffset() * 60000) % 86400000) / 1000 / 60
        ].sort();
        daySchedule.times[index].fromTime = values[0]
        daySchedule.times[index].toTime = values[1]
      }
      const newValue = value.map((item, vIndex) => {
        if (vIndex !== dayIndex) return item;
        return daySchedule;
      });
      return onChange(newValue);
    } else {
      const values = [
        ((rangeValue[0].toDate().getTime() + rangeValue[0].utcOffset() * 60000) % 86400000) / 1000 / 60,
        ((rangeValue[1].toDate().getTime() + rangeValue[1].utcOffset() * 60000) % 86400000) / 1000 / 60
      ].sort();
      const daySchedule = {
        fullDay: false,
        onAppointment: false,
        times: [{
          fromTime: values[0],
          toTime: values[1]
        }],
        dayOfWeek: day
      };
      return onChange([...value, daySchedule]);
    }
  }

  return (
    <section style={{width: '100%', opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto'}}>
      <>
        {/*<pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(value)}</pre>*/}
        {days.map((day) => {
          const dayValue = find(value, {dayOfWeek: day.key});
          const cd = new Date(0);
          return (
            <Row gutter={[16, 0]} key={day.key} style={{marginTop: '10px', backgroundColor: '#F0F0F0', borderRadius: '4px', padding: '10px 0'}}>
              <Col span={2}>
                {day.label}
              </Col>
              <Col span={4}>
                {/* <Checkbox checked={dayValue?.fullDay || false} onChange={(ev) => handleChange(day.key, null, null, 'fullDay', ev.target.checked)}>Toute la journée (24h/24)</Checkbox><br/> */}
                <Checkbox checked={dayValue?.onAppointment || false} onChange={(ev) => handleChange(day.key, null, null, 'onAppointment', ev.target.checked)}>Sur RDV</Checkbox>
              </Col>
              <Col span={18}>
                <>
                {dayValue?.times.map((time, index) => {
                  return (
                    <RangePicker
                      key={`${dayValue.dayOfWeek}_${index}`}
                      value={[moment(new Date(time.fromTime * 60 * 1000 + (cd.getTimezoneOffset() * 60 * 1000)), timeFormat),
                      moment(new Date(time.toTime * 60 * 1000 + (cd.getTimezoneOffset() * 60 * 1000)), timeFormat)]}
                      onChange={(value) => handleChange(day.key, index, value)}
                      hideDisabledOptions
                      minuteStep={15}
                      order
                      format={timeFormat}
                      bordered={false}
                    />
                  )
                })}
                </>
                <Space>
                  <Tooltip title="Ajouter un créneau">
                    <Button type="text" shape="round" onClick={() => addTimeRange(day.key)}>
                      <PlusOutlined /> Ajouter un créneau
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          );
        })}
      </>
    </section>
  );
}
