import React, {useState, useEffect} from 'react';
import {Row, Col, Divider, Typography} from 'antd';
import ConsumerForm from 'components/ConsumerForm';
import ProducerForm from 'components/ProducerForm';
import SelectProfile from 'components/SelectProfile';
import Logo from 'components/Logo';
import styles from './App.module.css';

const {Text} = Typography;

function App() {
  const [userProfile, stateSetUserProfile] = useState(null);
  useEffect(() => {
    const forceSuggest = /suggestProducer/.test(window.location.search);
    const forceRegister = /registerProducer/.test(window.location.search);
    const stUserProfile = window.localStorage.getItem('subscribeForm.userProfile');
    if (forceSuggest) {
      stateSetUserProfile('consumer');
    } else if (forceRegister) {
      stateSetUserProfile('producer');
    } else if (stUserProfile) {
      stateSetUserProfile(stUserProfile);
    }
  }, []);

  function setUserProfile(newUserProfile) {
    window.localStorage.setItem('subscribeForm.userProfile', newUserProfile);
    stateSetUserProfile(newUserProfile);
  }

  return (
    <div className={styles.App}>
      <Row>
        <Col span={20} offset={2}>
          <section className={styles.formContainer}>
            <Logo showPhoneNumber={userProfile === 'producer'} />
            <Divider />
            {userProfile === 'consumer' ? (
              <ConsumerForm onBack={() => setUserProfile(null)} />
            ) : null}
            {userProfile === 'producer' ? (
              <ProducerForm onBack={() => setUserProfile(null)} />
            ) : null}
            {userProfile !== 'consumer' && userProfile !== 'producer'? (
              <SelectProfile onSelectProfile={setUserProfile} />
            ) : null}

            <Text className={styles.copyright} type="secondary">Copyright {(new Date()).getFullYear()} - Sucrine Club - All right reserved</Text>
          </section>
        </Col>
      </Row>
    </div>
  );
}

export default App;
