import React, {useEffect, useState} from 'react';
import {find, map, reduce, padStart} from 'lodash';
import styles from './styles.module.css';

const dayInFrench = {
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  sunday: 'Dimanche'
};

function getTimeStr(time) {
  if (time < 0 || time > 1440) return '';
  return `${padStart(Math.floor(time / 60), 2, '0')}:${padStart(time % 60, 2, '0')}`
}



function AppPreview({site, formStatics}) {
  const [labels, setLabels] = useState({});

  useEffect(() => {
    if (!site || !formStatics) return;
    const nLabels = {};
    nLabels.siteType = find(formStatics.siteTypes, {type: site.site.siteType})?.label;
    nLabels.services = map(site.services, (service) => find(formStatics.services, {type: service})?.label).join(' · ')
    nLabels.paymentMeans = map(site.paymentMeans, (pMean) => find(formStatics.paymentMeans, {type: pMean})?.label);
    nLabels.services = map(site.services, (service) => find(formStatics.services, {type: service})?.label);
    nLabels.openingSchedule = map(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], (day) => {
      let m = find(site.site.openingSchedule, {dayOfWeek: day});
      if (site.site.siteType === 'market' && site.site.market.openingSchedule) {
        m = find(site.site.market.openingSchedule, {dayOfWeek: day});
      }
      if (!m) {
        return {day: dayInFrench[day], value: 'Fermé'}
      }
      return {
        day: dayInFrench[day],
        value: map(m.times, (time) => {
          if (time.fromTime < time.toTime) {
            return `De ${getTimeStr(time.fromTime)} à ${getTimeStr(time.toTime)}`;
          } else {
            return `De ${getTimeStr(time.toTime)} à ${getTimeStr(time.fromTime)}`;
          }
        }).join('\n')
      };
    });

    setLabels(nLabels);
  }, [site, formStatics]);

  if (!site || !labels) {
    return null;
  }

  return (
    <section className={styles.appPreview}>
      {site.mainPicture ? (
        <div className={styles.picturePreview} style={{backgroundImage: `url(${site.mainPicture})`}}></div>
      ) : (
        <div className={styles.picturePreview} style={{backgroundColor: '#999'}}></div>
      )}
      <div className={styles.appContent}>
        <div className={styles.title}>{site.name || site.companyName}</div>
        <div className={styles.subtitle}>{site.subtitle}</div>

        <div className={styles.types}>{labels.siteType}</div>
        <div className={styles.types}>{labels.services}</div>
        <div className={styles.types}>Fermé actuellement</div>
        <div className={styles.types}>{site.site.location?.city || site.site.market?.location?.city}</div>

        <div className={styles.categoryTitle}>Catégories de produits</div>
        <div className={styles.categories}>
          {(site.basicCatalogue || []).map((basicCatalogueItem) => {
            const item = find(formStatics.searchForm.categories, {_id: basicCatalogueItem});
            if (!item) return null;
            return (
              <div key={item.name} className={styles.productCategory}>{item.name}</div>
            );
          })}
        </div>

        {site.tags_productBulk || site.tags_productOrganic ? (
          <>
            <div className={styles.categoryTitle}>Types de produits</div>
            <div className={styles.chipContainer}>
              {site.tags_productBulk && (
                <div className={styles.chip}>Produits en vrac</div>
              )}
              {site.tags_productOrganic && (
                <div className={styles.chip}>Produits bio</div>
              )}
            </div>
          </>
        ) : null}

        {site.usefulInformations && (
          <>
            <div className={styles.categoryTitle}>Informations pratiques</div>
            <div className={styles.paragraph}>
              {site.usefulInformations}
            </div>

          </>
        )}

        {labels.paymentMeans && labels.paymentMeans.length > 0 && (
          <>
            <div className={styles.categoryTitle}>Moyens de paiement</div>
            <div className={styles.chipContainer}>
              {labels.paymentMeans.map((pMean) => (
                <div key={pMean} className={styles.chip}>{pMean}</div>
              ))}
            </div>
          </>
        )}

        <div className={styles.categoryTitle}>Coordonnées</div>
        <div className={styles.types}>Nous rendre visite</div>
        <div className={styles.types}>Nous appeler ({site.site?.contacts?.phoneNumber || site.contacts_phoneNumber})</div>
        <div className={styles.types}>Nous écrire ({site.site?.contacts?.email || site.contacts_email})</div>
        {site.contacts_website && (<div className={styles.types}>Visiter notre site internet</div>)}

        {labels.services && labels.services.length > 0 && (
          <>
            <div className={styles.categoryTitle}>Services disponibles</div>
            <div className={styles.chipContainer}>
              {labels.services.map((service) => (
                <div key={service} className={styles.chip}>{service}</div>
              ))}
            </div>
          </>
        )}

        {(site.site.siteType !== 'market' && (site.site.openingSchedule || site.site.openingScheduleDescription)) && (
          <>
            <div className={styles.categoryTitle}>Horaires d'ouverture</div>
            {site.site.openingScheduleDescription && (
              <div className={styles.paragraph}>
                {site.site.openingScheduleDescription}
              </div>
            )}
            {site.site.openingSchedule && !site.site.noOpeningSchedule && (
              <div className={styles.schedule}>
                {labels.openingSchedule?.map((line) => (
                  <div key={line.day} className={styles.scheduleLine}>
                    <div className={styles.day}>{line.day}</div>
                    <div className={styles.value}>{line.value}</div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        {(site.site.siteType === 'market' && site.site.market?.openingSchedule) && (
          <>
            <div className={styles.categoryTitle}>Horaires d'ouverture</div>
            {site.site.market.openingSchedule && (
              <div className={styles.schedule}>
                {labels.openingSchedule?.map((line) => (
                  <div key={line.day} className={styles.scheduleLine}>
                    <div className={styles.day}>{line.day}</div>
                    <div className={styles.value}>{line.value}</div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        {site.description && (
          <>
            <div className={styles.categoryTitle}>Notre histoire</div>
            <div className={styles.paragraph}>
              {site.description}
            </div>

          </>
        )}

        {site.productDescription && (
          <>
            <div className={styles.categoryTitle}>Nos produits en détail</div>
            <div className={styles.paragraph}>
              {site.productDescription}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default function AppPreviews({formContent, formStatics}) {
  const [allSites, setAllSites] = useState([]);

  useEffect(() => {
    if (!formContent || !formStatics) return;
    const mergeContent = reduce(formContent, (acc, step) => {
      return {
        ...acc,
        ...step
      };
    }, {});
    const sites = mergeContent.saleSites.map((saleSite) => {
      return {
        ...mergeContent,
        saleSites: undefined,
        site: saleSite
      };
    });

    setAllSites(sites);
  }, [formContent, formStatics]);



  return (
    <section className={styles.root}>
      <h1>App preview</h1>
      <section className={styles.previews}>
        {allSites.map((site, index) => (
          <AppPreview key={`site_${index}`} site={site} formStatics={formStatics} />
        ))}
      </section>
    </section>
  );
}