import {Select} from 'antd';
import React from 'react';
import styles from './styles.module.css';

const emptyArray = [];

export default function BasicCatalogueInput({searchFormContent, value, onChange}) {
  return (
    <Select className={styles.root} onChange={onChange} value={value} mode="multiple">
      {((searchFormContent && searchFormContent.categories) || emptyArray).map((category) => {
        return (
          <Select.Option key={category._id}>{category.name}</Select.Option>
        );
      })}
    </Select>
  )
}