import React from 'react';
import {Row, Col, Button, Space} from 'antd';
import styles from './styles.module.css';

export default function SelectProfile({onSelectProfile}) {
  return (
    <Row>
      <Col span={20} offset={2}>
        <Space direction="vertical" className={styles.space}>
          <Button
            className={styles.button}
            onClick={() => onSelectProfile('producer')}
            size="large"
            type="primary"
            block
          >Je suis un professionnel et je souhaite m'inscrire</Button>
          <Button
            className={styles.button}
            onClick={() => onSelectProfile('consumer')}
            size="large"
            type="primary"
            block
          >Je suis un particulier et je souhaite suggérer un professionnel</Button>
        </Space>
      </Col>
    </Row>
  );
}