import {Select, Typography} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {findDOMNode} from 'react-dom';
import {find} from 'lodash';
import styles from './styles.module.css';

const {Text} = Typography;
const {Option} = Select;

const daysOfWeekFrench = {
  'monday': 'Lundi',
  'tuesday': 'Mardi',
  'wednesday': 'Mercredi',
  'thursday': 'Jeudi',
  'friday': 'Vendredi',
  'saturday': 'Samedi',
  'sunday': 'Dimanche'
};

const dayPartsFrench = [
  {
    medMin: 300,
    medMax: 720,
    durationMin: 60,
    durationMax: 400,
    label: 'matin'
  },
  {
    medMin: 720,
    medMax: 1080,
    durationMin: 60,
    durationMax: 420,
    label: 'après-midi'
  },
  {
    medMin: 1080,
    medMax: 1440,
    durationMin: 60,
    durationMax: 300,
    label: 'soir'
  }
];

export default function MarketSelector({markets, value, onChange}) {
  const [marketMap, setMarketMap] = useState({});
  const selectRef = useRef();

  useEffect(() => {
    const nMarketMap = {};
    if (!Array.isArray(markets)) return;
    (markets || []).forEach((market) => {
      const days = [];
      const daysShedules = [];
      market.openingSchedule.forEach((sched) => {
        if (!sched.times || sched.times.length === 0) return;
        const med = (sched.times[0].fromTime + sched.times[0].toTime) / 2;
        const duration = sched.times[0].toTime - sched.times[0].fromTime;
        const dayOfWeek = daysOfWeekFrench[sched.dayOfWeek];
        const dayPart = find(dayPartsFrench, dp => dp.medMin <= med && dp.medMax >= med && dp.durationMin <= duration && dp.durationMax >= duration);
        if (dayOfWeek) {
          days.push({
            label: dayPart ? `${dayOfWeek} ${dayPart.label}` : dayOfWeek,
            value: sched._id
          });
          daysShedules.push(sched);
        }
      });

      nMarketMap[market._id] = {
        ...market,
        days,
        daysShedules
      };
    });
    setMarketMap(nMarketMap);
  }, [markets]);

  useEffect(() => {
    try {
      const d = findDOMNode(selectRef.current);
      const input = d.querySelector('input');
      input.setAttribute('autocomplete', 'newpassword');
    } catch(err) {}
  }, [selectRef]);

  function handleMarketChange(marketId) {
    if (marketId === 'other') {
      return onChange({_id: 'other'});
    }
    return onChange(marketMap[marketId]);
  }

  return (
    <Select
      ref={selectRef}
      className={styles.root}
      showSearch
      allowClear
      onChange={handleMarketChange}
      value={typeof value === 'string' ? value : value?._id}
      placeholder="Rechercher un marché (nom, ville, code postal...)"
      optionFilterProp="children"
      filterOption={(input, option) => {
        const market = marketMap[option.value];
        const value = input.toLowerCase();
        if (option.value === 'other') return true;
        if (!market) return false;
        return market.name.toLowerCase().indexOf(value) >= 0 || market.location.city.toLowerCase().indexOf(value) >= 0 || (value.length > 1 && market.location.zipcode.startsWith(value));
      }}
      filterSort={(optionA, optionB) => {
        const marketA = marketMap[optionA.value];
        const marketB = marketMap[optionB.value];
        if (optionA.value === 'other') return 1;
        if (optionB.value === 'other') return -1;
        return marketA.name.toLowerCase().localeCompare(marketB.name.toLowerCase());
      }}
    >
      <Option value="other">Ajouter un marché</Option>
      {Object.values(marketMap).map((market) => (
        <Option value={market._id} key={market._id}>
          <section>
            <Text strong>{market.name}</Text> - <Text>{market.days ? market.days.map(a => a.label).join(', ') : ''}</Text><br/>
            <Text>{market.location.zipcode} {market.location.city}</Text>
          </section>
        </Option>
      ))}
    </Select>
  );
}