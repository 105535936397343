import React, {useState, useEffect} from 'react';
import {Form, Input, Row, Col, Typography} from 'antd';
import styles from './styles.module.css';

const {Title} = Typography;

export default function MarketForm({onChange}) {
  const [currentMarket, setCurrentMarket] = useState({});

  useEffect(() => {
    onChange(currentMarket);
  }, [currentMarket, onChange]);

  function handleFieldChange(field) {
    return (ev) => {
      const value = typeof ev === 'string' ? ev : ev.currentTarget.value;
      setCurrentMarket({
        ...currentMarket,
        [field]: value
      });
    }
  }

  return (
    <Row className={styles.root}>
      <Col offset={1} span={22}>
        <Title level={5}>Ajouter un marché</Title>
        <Form.Item label="Nom du marché">
          <Input placeholder="Nom" value={currentMarket.name} onChange={handleFieldChange('name')} />
        </Form.Item>

        <Form.Item label="Adresse">
          <Input placeholder="Adresse" value={currentMarket.address} onChange={handleFieldChange('address')} />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Code postal">
              <Input placeholder="Code postal" value={currentMarket.zipcode} onChange={handleFieldChange('zipcode')} />
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item label="Ville">
            <Input placeholder="Ville" value={currentMarket.city} onChange={handleFieldChange('city')} />
          </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Jours de présence">
          <Input placeholder="Jours de présence" value={currentMarket.days} onChange={handleFieldChange('days')} />
        </Form.Item>
      </Col>
    </Row>
  )
}