import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import './index.less';
import App from './App';
import pkg from '../package.json';

Sentry.init({
  dsn: "https://b0236b43cbf04883aabcb58f23a94051@sentry.conseil.sucrine.club/4",
  integrations: [new Integrations.BrowserTracing()],
  release: `${pkg.name}@${pkg.version}`,
  environment: window.location.hostname === 'form.sucrine.club' ?
    'production' : (
      window.location.hostname === 'preview.form.sucrine.club' ?
        'preview' : (
          window.location.hostname === 'localhost' ? 'development' : 'unknown'
        )
    ),
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
