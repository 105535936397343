import React, {useEffect, useState, useRef} from 'react';
import {Radio, Button} from 'antd';
import {throttle} from 'lodash';
import {FullscreenOutlined} from '@ant-design/icons'
/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './style.module.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mapBoxApiKey = 'pk.eyJ1IjoibHBlbmF1ZCIsImEiOiJja3IyOWxxcDkyOWR3MzFxcDl6NDVyeHEwIn0.rTy3hffJaDGKECqiY3OhqQ';

// mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = mapBoxApiKey;

const defaultLocation = [2.36, 46.33];

export default function MapLocator({onChange, value, address, zipcode, city}) {
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [viewMode, setViewMode] = useState('streets');
  const [manuallyMoved, setManuallyMoved] = useState(false);
  const mapContainer = useRef();
  const map = useRef();
  const marker = useRef();

  const throttledUpdateLocationFromProps = useRef(throttle(updateLocationFromProps, 1000));

  useEffect(() => {
    if (manuallyMoved) return;
    throttledUpdateLocationFromProps.current(address, zipcode, city);
  }, [address, zipcode, city, manuallyMoved]);

  function updateLocationFromProps(address, zipcode, city) {
    if (!/^[0-9]{5}$/.test((zipcode || '').toString())) return;
    const strAddress = `${address || ''},${zipcode} ${city}`;
    fetch('/api/maps/geocode', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({address: strAddress})
    })
      .then(res => res.json())
      .then((response) => {
        if (response?.results?.[0]?.geometry?.location) {
          const {lat, lng} = response.results[0].geometry.location;
          onChange([lng, lat]);
          map.current.setCenter([lng, lat]);
          map.current.setZoom(12);
          // console.log(response.results[0].geometry.location);
        }
      });
  }

  useEffect(() => {
    if (!mapContainer.current) return;
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      // style: 'mapbox://styles/mapbox/streets-v11',
      style: `mapbox://styles/mapbox/${viewMode}-v11`,
      center: value,
      zoom: value[0] === defaultLocation[0] && value[1] === defaultLocation[1] ? 4 : 12
    });
  }, [map, mapContainer, viewMode, value]);

  useEffect(() => {
    if (marker.current) {
      marker.current.remove();
    }
    marker.current = new mapboxgl.Marker({
      draggable: true
    })
      .setLngLat(value)
      .addTo(map.current);

    marker.current.on('dragend', (ev) => {
      const coordinates = ev.target.getLngLat();
      setManuallyMoved(true);
      onChange([coordinates.lng, coordinates.lat]);
    });
  }, [value, map, onChange]);

  useEffect(() => {
    map.current.resize();
  }, [fullscreenMode]);

  useEffect(() => {
    map.current.setStyle(`mapbox://styles/mapbox/${viewMode}-v11`);
  }, [viewMode]);

  return (
    <div className={styles.root}>
      <div className={fullscreenMode ? styles.fullscreen : styles.regular}>
        <div ref={mapContainer} style={{height: '100%'}} className="map-container" />
        <Radio.Group className={styles.viewModeSelector} value={viewMode} onChange={(ev) => setViewMode(ev.target.value)}>
          <Radio.Button value="streets">Vue plan</Radio.Button>
          <Radio.Button value="satellite-streets">Vue satellite</Radio.Button>
        </Radio.Group>
        <Button
          onClick={() => setFullscreenMode(!fullscreenMode)}
          className={styles.fullscreenButton}
          icon={<FullscreenOutlined />}
        />
      </div>
    </div>
  );
}
