import {Form, Input, Row, Col, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {filter} from 'lodash';
import styles from './styles.module.css';

const emptyArray = [];
const {Title} = Typography;

export default function SocialInput({socialLinks, value, onChange}) {
  const [socialLinksValues, setSocialLinksValues] = useState({});

  useEffect(() => {
    const result = {};
    if (value) {
      value.forEach((item, index) => {
        result[item.type] = {
          index,
          ...item
        };
      });
      setSocialLinksValues(result);
    }
  }, [value]);

  function handleSocialChange(socialType, valueType) {
    return (ev) => {
      const result = value ? [...value] : [];
      if (socialLinksValues[socialType]) {
        result[socialLinksValues[socialType].index] = {
          ...result[socialLinksValues[socialType].index],
          [valueType]: ev.currentTarget.value
        };
      } else {
        result.push({
          type: socialType,
          label: '',
          value: '',
          [valueType]: ev.currentTarget.value
        });
      }
      onChange(filter(result, (link) => link.label !== '' || link.value !== ''));
    };
  }

  return (
    <section className={styles.root}>
      {(socialLinks || emptyArray).map((socialLink) => (
          <Row key={socialLink.type} gutter={16} className={styles.socialRow}>
            <Col span={24}>
              <Title level={5} style={{margin: 0}}>{socialLink.label}</Title>
            </Col>
            <Col xl={16} md={24}>
              <Form.Item label="Identifiant">
                <Input placeholder={socialLink.hint || (`Identifiant ${socialLink.label}`)} value={socialLinksValues[socialLink.type] ? socialLinksValues[socialLink.type].value : ''} onChange={handleSocialChange(socialLink.type, 'value')} />
              </Form.Item>
            </Col>
            <Col xl={8} md={24}>
              <Form.Item label="Nom affiché dans l'application">
                <Input placeholder="Nom affiché dans l'application" value={socialLinksValues[socialLink.type] ? socialLinksValues[socialLink.type].label : ''} onChange={handleSocialChange(socialLink.type, 'label')} />
              </Form.Item>
            </Col>
          </Row>
        )
      )}
    </section>
  )
}