import React, {useState, useEffect} from 'react';
import {Row, Col, Radio, Steps, Select, Typography, Input, Form, Button, message} from 'antd';
import {reduce} from 'lodash';
import {LoadingOutlined, CaretLeftOutlined} from '@ant-design/icons';
import styles from './styles.module.css';

const {Step} = Steps;
const {Title} = Typography;
const maxStep = 1;

export default function ProducerForm({onBack}) {
  const [currentStep, stateSetCurrentStep] = useState(0);
  const [fullFormContent, setFullFormContent] = useState([]);
  const [formStatics, setFormStatics] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  function loadFormContent() {
    fetch('/api/siteTypes')
      .then(res => res.json())
      .then((res) => {
        setFormStatics({
          siteTypes: res
        });
    });
  }

  useEffect(() => {
    const newStep = window.localStorage.getItem('subscribeForm.consumer.currentStep');
    if (newStep) {
      stateSetCurrentStep(parseInt(newStep, 10));
    }

    const stFullFormContent = [];
    for (let i = 0; i < maxStep; i++) {
      const stStepContent = window.localStorage.getItem(`subscribeForm.consumer.step.${i}`);
      if (stStepContent) {
        try {
          const content = JSON.parse(stStepContent);
          stFullFormContent[i] = content;
        } catch (err) {
          console.error(err);
        }
      }
    }

    function loadFormValues(values) {
      let agg = {};
      values.forEach((value) => {
        agg = {...agg, ...value};
      });
      form.setFieldsValue(agg);
      setFullFormContent(values);
    }

    loadFormValues(stFullFormContent);

    loadFormContent();
  }, [form]);



  function setCurrentStep(newStep) {
    if (newStep <= maxStep) {
      window.localStorage.setItem('subscribeForm.consumer.currentStep', newStep);
    }
    stateSetCurrentStep(newStep);
  }

  async function handleSubmit(values) {
    const formContent = [...fullFormContent];
    formContent[currentStep] = values;
    window.localStorage.setItem(`subscribeForm.consumer.step.${currentStep}`, JSON.stringify(formContent[currentStep]));
    setFullFormContent(formContent);

    if (currentStep === maxStep) {
      // concat formContent
      const mergeContent = reduce(formContent, (acc, step) => {
        return {
          ...acc,
          ...step
        };
      }, {});

      setSubmitting(true);
      fetch('/api/recommend', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(mergeContent)
      })
      .then((res) => res.json())
      .then((result) => {
        setSubmitting(false);
        if (result.status === true) {
          window.localStorage.removeItem('subscribeForm.consumer.currentStep');
          window.localStorage.removeItem('subscribeForm.userProfile');
          for (let i = 0; i < maxStep; i++) {
            window.localStorage.removeItem(`subscribeForm.consumer.step.${i}`);
          }
          setCurrentStep(currentStep + 1);
        } else {
          message.error('Une erreur est survenue. Merci de réessayer. Si le problème persiste, contactez-nous.');
        }
      })
      .catch((err) => {
        setSubmitting(false);
        message.error('Une erreur est survenue. Merci de réessayer. Si le problème persiste, contactez-nous.');
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  const buttons = (
    <Row>
      <Col offset={2}>
        {currentStep <= maxStep ? (
          <Button disabled={currentStep <= 0} onClick={() => setCurrentStep(currentStep - 1)}>Précédent</Button>
        ) : null}
      </Col>
      <Col flex={1}></Col>
      <Col>
        {currentStep === maxStep && (
          <Button type="primary" htmlType="submit">Soumettre</Button>
        )}

        {currentStep < maxStep && (
          <Button disabled={currentStep >= maxStep} type="primary" htmlType="submit">Suivant</Button>
        )}
      </Col>
      <Col span={2}></Col>
    </Row>
  );

  function handleStepChange(newStep) {
    // save current step
    if (currentStep >= maxStep) {
      return;
    }
    if (fullFormContent && fullFormContent[newStep]) {
      setCurrentStep(newStep);
    }
  }

  function resetForm() {
    form.resetFields();
    setCurrentStep(0);
  }

  return (
    <section className={styles.root}>
      <Button type="primary" shape="round" style={{margin: '0 20px 20px 20px'}} icon={<CaretLeftOutlined />} onClick={onBack}>Retour</Button>
      <Row>
        <Col lg={4} md={6} xs={22} offset={1}>
          <Steps current={currentStep} onChange={handleStepChange} direction="vertical">
            <Step title="Le professionnel" description="Qui vous souhaitez recommander ?" />
            <Step title="Vos coordonnées" description="Qui êtes vous" icon={submitting ? (<LoadingOutlined />) : null} />
            <Step title="Statut" description="Envoi du formulaire" />
          </Steps>
        </Col>


        <Col lg={18} md={16} xs={24}>
          <Form
            layout="vertical"
            form={form}
            initialValues={{}}
            onFinish={handleSubmit}
          >
            <Row>
              <Col span={20} offset={2} className={styles.formContent}>
                {currentStep === 0 ? (
                  <>
                    <Form.Item
                      label="Nom de la société"
                      name="companyName"
                      required
                      rules={[{required: true, message: 'Le nom de la société est obligatoire.'}]}
                    >
                      <Input placeholder="GAEC de Tournefeuille" />
                    </Form.Item>

                    <Form.Item
                      label="Adresse de la société"
                      name="companyAddress"
                      required
                      rules={[{required: true, message: 'L\'adresse de la société est obligatoire.'}]}
                    >
                      <Input placeholder="Centre-ville, Tournefeuille" />
                    </Form.Item>

                    <Form.Item
                      label="Comment le contacter"
                      extra="Numéro de téléphone, adresse email, site internet ou réseaux sociaux..."
                      name="companyContact"
                      required
                      rules={[{required: true, message: 'Un moyen de contacter la société est obligatoire.'}]}
                    >
                      <Input placeholder="06 22 33 44 55" />
                    </Form.Item>

                    <Form.Item
                      label="Type de lieu de vente"
                      required
                      name="siteType"
                      rules={[{required: true, message: 'Le type de lieu de vente est requis.'}]}
                    >
                      <Select style={{flex: 1}}>
                        {((formStatics && formStatics.siteTypes) || []).map((siteType) => (
                          <Select.Option key={siteType.type} value={siteType.type}>{siteType.label}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ) : null}

                {currentStep === 1 ? (
                  <>
                    <Form.Item label="Votre prénom et nom" name="userName" required rules={[{required: true, message: 'Votre prénom et nom sont obligatoires.'}]}>
                      <Input placeholder="Prénom et nom" />
                    </Form.Item>

                    <Form.Item label="Votre adresse email" name="userEmail" required rules={[{required: true, message: 'Votre email est obligatoire.'}, {type: 'email', message: 'L\'email doit être valide.'}]}>
                      <Input placeholder="Email" />
                    </Form.Item>

                    <Form.Item label="A quelle fréquence allez-vous chez ce professionnel ?" name="howOften" required rules={[{required: true, message: 'La fréquence de visite est obligatoire.'}]}>
                        <Radio.Group>
                          <Radio value="often">Régulièrement</Radio>
                          <Radio value="sometimes">De temps en temps</Radio>
                          <Radio value="never">Jamais</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Comment avez-vous connu le projet ?" name="howDidYouKnowSucrine">
                      <Input placeholder="Comment avez-vous connu le projet ?" />
                    </Form.Item>
                  </>
                ) : null}

                {currentStep === 2 ? (
                  <>
                    <Title level={1}>
                      Merci !
                    </Title>
                    <p>
                      Le formulaire a bien été envoyé. Nous contacterons le professionnel dès que possible.
                    </p>
                    <Button onClick={() => resetForm(0)}>Soumettre une nouvelle adresse ?</Button>
                  </>
                ) : null}
              </Col>
            </Row>
            {buttons}
          </Form>
        </Col>
      </Row>
    </section>
  );
}